<template>
    <v-container v-if="LearningObjectives" fluid fill-height v-bind:style="{ 'background-image': 'url(' + require(`@/assets/img/`+LearningObjectives.background) + ')', 'background-size': 'cover'}" >
        <transition name="fade-transition">
            <v-container class="learning-objectives-wrapper" style="min-height:calc(80vh)" v-if="showContent">
                <h2 class="medium-text custom-font">WHAT YOU WILL LEARN</h2>
                <v-row no-gutters>
                    <v-col cols="12" sm="12">
                        <ul v-if="LearningObjectives.PrimaryMsg">
                            <template v-for="(item,index) in LearningObjectives.PrimaryMsg">
                                <li class="primary-msg" :key="index" v-if="index<(LearningObjectives.PrimaryMsg.length-1) && index <4">
                                    {{item}}
                                </li>
                                <li class="primary-msg" :key="index" v-if="index == (LearningObjectives.PrimaryMsg.length-1) && index <4">
                                    {{item}} <p class="secondary-msg" v-if="LearningObjectives.Narration">[{{LearningObjectives.Narration}}]</p>
                                </li>
                            </template> 
                        </ul>
                    </v-col>
                    <v-col cols="12" sm="10" v-if="LearningObjectives.PrimaryMsg.length>3">
                        <ul v-if="LearningObjectives.PrimaryMsg">
                            <template v-for="(item,index) in LearningObjectives.PrimaryMsg">
                                <li class="primary-msg" :key="index" v-if="index<(LearningObjectives.PrimaryMsg.length-1) && index >3">
                                    {{item}}
                                </li>
                                <li class="primary-msg" :key="index" v-if="index == (LearningObjectives.PrimaryMsg.length-1) && index >3">
                                    {{item}} <p class="secondary-msg" v-if="LearningObjectives.Narration">[{{LearningObjectives.Narration}}]</p>
                                </li>
                            </template> 
                        </ul>
                    </v-col>
                </v-row>
                <transition name="slide-fade">
                    <v-img src="@/assets/2b-Happy-talk.png" width="350" height="400" v-if="showTwoB" class="twoB" :class="twoBclass"></v-img>
                </transition>
                <transition name="fade-transition">
                    <v-btn class="skip custom-font" v-if="objectiveSkip" @click="skipObjective" :disabled="btnDisabled">Continue</v-btn>
                </transition>
                <v-icon class="back-icon" @click="$router.push({ name: 'Lessons', params: { id: '6a6b5c5d9e9', name: 'Earth and Space', slug: 'earth-and-space' } })">
                    west
                </v-icon>
            </v-container>
        </transition>
    </v-container>    
</template>
<script>
export default {
    name: 'LearningObjectives',
    data(){
        return{
            showTwoB: false,
            showContent: false,
            btnDisabled: false,
            twoBclass: '',
            x:0
        }
    },
    mounted(){
        this.objectiveSkip = false;
        this.$store.dispatch('getLearningObjective', this.$route.params.id)
    },
    computed:{
        objectiveSkip:{
            set(value){
                this.$store.state.objectiveSkip = value
            },
            get(){
                return this.$store.state.objectiveSkip
            }
        },
        LearningObjectives(){
            return this.$store.state.LearningObjectives
        },
    },
    created(){
        var _this = this;

        setTimeout(() => {
            _this.showContent = true;
            if(_this.LearningObjectives.PrimaryMsg.length>3){
                _this.twoBclass = 'lengthy-content'
            }
        }, 1500);

        setTimeout(() => {
            _this.showTwoB = true;
            setTimeout(() => {
                _this.objectiveSkip = true;
            }, 500);
        }, 2500);
    },
    methods:{
        skipObjective(){
            this.btnDisabled= true;
            var _this = this;
            this.showTwoB = false;
            setTimeout(() => {
                _this.showContent = false;
            }, 1500);
            setTimeout(() => {
                _this.$router.push({name: 'Activity', params: { id: _this.$store.state.Activities[0].id, name: _this.$store.state.Activities[0].name, lessonID: _this.$route.params.id} })
            }, 2000);
        },
    }
}
</script>
<style scoped>
    .medium-text.custom-font{
        margin: 15px 0px 30px;
    }
    .learning-objectives-wrapper{
        background-color: rgba(255,255,255,0.5);
        padding: 15px 50px;
    }
    .primary-msg{
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 1.1rem;
    }
    .secondary-msg{
        display: inline;
        font-weight: 400;
        font-style: italic;
    }
    .twoB{
        position: fixed;
        bottom: -80px;
        right: 100px;
    }
    .twoB.lengthy-content{
        right: -50px;
    }
    .slide-fade-enter-active {
        transition: all 1s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(300px);
        opacity: 0;
    }
    .v-application a{
        text-decoration: none;
        color: #fff
    }
    .back-btn, .back-icon{
        position: fixed;
        left: 15px;
        top: 30px;
        color: #fff;
        font-size: 46px;
        transition: all 0.3s;
        cursor: pointer;
        -webkit-text-stroke: 1px black;
    }
    .back-btn:hover, .back-icon:hover{
        text-shadow: 2px 2px 2px rgba(255,255,255,0.5);
        color: #6FC41E;
    }

</style>